import React, { useState } from "react";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { ref, getStorage } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import { doc, setDoc, getFirestore } from "firebase/firestore";

const Account = () => {
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const handlePasswordChange = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        alert("Passwort erfolgreich geändert!");
      } catch (error) {
        console.error("Fehler beim Passwort-Update:", error);
      }
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;

    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${selectedImage.name}`);
    try {
      const imageUrl = await getDownloadURL(storageRef);

      // Save this URL to Firestore under roles > uid > image
      const db = getFirestore();
      const userRef = doc(db, "roles", getAuth().currentUser.uid);
      await setDoc(userRef, { image: imageUrl }, { merge: true });

      alert("Profilbild erfolgreich hochgeladen!");
    } catch (error) {
      console.error("Fehler beim Bild-Upload:", error);
    }
  };

  const handleImageSelection = (event) => {
    const file = event.target.files[0];
    const validFileTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (file && validFileTypes.includes(file.type)) {
      setSelectedImage(file);
    } else {
      alert("Bitte ein gültiges Bildformat (.jpeg, .jpg, .png) auswählen!");
    }
  };

  return (
    <div>
      <h2>Account Einstellungen</h2>
      <div>
        <h3>Passwort ändern</h3>
        <input
          type="password"
          placeholder="Aktuelles Passwort"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Neues Passwort"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button onClick={handlePasswordChange}>Passwort ändern</button>
      </div>

      <div>
        <h3>Profilbild ändern</h3>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleImageSelection}
        />
        <button onClick={handleImageUpload}>Bild hochladen</button>
      </div>
    </div>
  );
};

export default Account;
