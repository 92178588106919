import React from "react";
import Button from "@mui/material/Button"; 
import Box from "@mui/material/Box";

const TicketTabs = ({ activeStatus, setStatusFilter }) => {
  return (
    <Box display="flex" justifyContent="center" gap={2}>
      <Button
        variant={activeStatus === "neu" ? "contained" : "outlined"}
        color="primary"
        onClick={() => setStatusFilter("neu")}
      >
        Neu
      </Button>
      <Button
        variant={activeStatus === "inBearbeitung" ? "contained" : "outlined"}
        color="primary"
        onClick={() => setStatusFilter("inBearbeitung")}
      >
        In Bearbeitung
      </Button>
      <Button
        variant={activeStatus === "erledigt" ? "contained" : "outlined"}
        color="primary"
        onClick={() => setStatusFilter("erledigt")}
      >
        Erledigt
      </Button>
    </Box>
  );
};

export default TicketTabs;
