import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCD2RpPwLsbNCllqzL9eJLNB75Gsd3hgTo",
  authDomain: "themsorun.firebaseapp.com",
  databaseURL:
    "https://themsorun-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "themsorun",
  storageBucket: "themsorun.appspot.com",
  messagingSenderId: "99271050624",
  appId: "1:99271050624:web:039a8b403220c033a66d65",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default app;
export { db };
