import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import TicketModal from "./TicketModal";
import StatusSymbol from "../helpers/StatusSymbol";
import TicketTabs from "../helpers/TicketTabs";
import styled from "styled-components";

function Dashboard() {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [role, setRole] = useState("");
  const [statusFilter, setStatusFilter] = useState("neu"); // Add status filter

  const ticketsToShow = tickets.filter(
    (ticket) => ticket.status === statusFilter
  );

  const db = getFirestore();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchUserRole = async () => {
      if (!currentUser) return; // Prevents continuing if currentUser does not exist

      const userRef = doc(db, "roles", currentUser.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        setRole(userDoc.data().role);
      }
    };

    const fetchTickets = async () => {
      let ticketRef;
      if (role === "customer") {
        ticketRef = query(
          collection(db, "tickets"),
          where("userId", "==", currentUser.uid)
        );
      } else {
        ticketRef = collection(db, "tickets");
      }

      const ticketSnapshot = await getDocs(ticketRef);
      const ticketData = ticketSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTickets(ticketData);
    };

    fetchUserRole();
    fetchTickets();
  }, [db, role, currentUser]);

  const openTicketDetails = (ticket) => {
    setSelectedTicket(ticket);
  };

  const closeTicketDetails = () => {
    setSelectedTicket(null);
  };

  return (
    <>
      {/* <LeftSidebar /> */}
      <MainContent>
        <h1>Dashboard</h1>

        <StatusContainer>
          <TicketTabs
            activeStatus={statusFilter}
            setStatusFilter={setStatusFilter}
          />
        </StatusContainer>

        {role === "customer" && (
          <div>
            <h2>Meine Tickets</h2>
            {ticketsToShow.map((ticket, index) => (
              <TicketItem key={index} onClick={() => openTicketDetails(ticket)}>
                <StatusItem>
                  <StatusSymbol status={ticket.status} />
                </StatusItem>
                {ticket.subject}
              </TicketItem>
            ))}
          </div>
        )}

        {role === "admin" && (
          <div>
            {/* <select onChange={handleCompanyChange}> ... (Existing code) */}
            <h2>Alle Tickets</h2>
            {ticketsToShow.map((ticket, index) => (
              <TicketItem key={index} onClick={() => openTicketDetails(ticket)}>
                <StatusItem>
                  <StatusSymbol status={ticket.status} />
                </StatusItem>
                {ticket.company}: {ticket.subject}
              </TicketItem>
            ))}
          </div>
        )}

        {selectedTicket && (
          <TicketModal ticket={selectedTicket} onClose={closeTicketDetails} />
        )}
      </MainContent>
    </>
  );
}

export default Dashboard;

const MainContent = styled.div`
  margin: 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StatusItem = styled.div`
  padding: 8px 16px;
  margin-top: 8px;
`;

const TicketItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
