import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const AddUser = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [unternehmen, setUnternehmen] = useState("");
  const [position, setPosition] = useState("");
  const [error, setError] = useState(null);

  const handleAddUser = async () => {
    try {
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        "tempPassword123"
      );

      const db = getFirestore();
      await addDoc(collection(db, "users"), {
        uid: userCredential.user.uid,
        email,
        name,
        role,
        unternehmen,
        position,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="text"
        placeholder="Name"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        placeholder="Role"
        onChange={(e) => setRole(e.target.value)}
      />
      <input
        type="text"
        placeholder="Unternehmen"
        onChange={(e) => setUnternehmen(e.target.value)}
      />
      <input
        type="text"
        placeholder="Position"
        onChange={(e) => setPosition(e.target.value)}
      />
      <button onClick={handleAddUser}>Add User</button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default AddUser;
