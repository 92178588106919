import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routes";
import { ThemeProvider, createTheme } from "@mui/material/styles";

function App() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
