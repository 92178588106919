import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  addDoc,
  collection,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useNavigate } from "react-router-dom";

function CreateTicket() {
  const secondaryOptionsMap = {
    hardware: [
      "Laptop",
      "Handy",
      "Monitor",
      "Tastatur & Maus",
      "Drucker",
      "Beamer",
      "TV",
      "Andere",
    ],
    software: ["Office", "Teams", "Outlook", "Word", "Excel", "Andere"],
    network: ["Internet", "Verbindungen", "Geschwindigkeit", "Andere"],
    "Gerät einrichten": [
      "Laptop",
      "Handy",
      "Netzwerk",
      "Drucker",
      "Monitor",
      "Tastatur & Maus",
      "Beamer",
      "TV",
      "Andere",
    ],
    orders: [],
    setupDevice: [
      "Laptop",
      "Handy",
      "Netzwerk",
      "Drucker",
      "Monitor",
      "Büroausstattung",
      "Andere",
    ],
  };

  const [secondaryOptions, setSecondaryOptions] = useState([]);

  function handleIssueTypeChange(event) {
    setIssueType(event.target.value);
    if (secondaryOptionsMap[event.target.value]) {
      console.log(
        "Setting secondary options to:",
        secondaryOptionsMap[event.target.value]
      );
      setSecondaryOptions(secondaryOptionsMap[event.target.value]);
      console.log(
        "secondaryOptions after setting:",
        secondaryOptionsMap[event.target.value]
      );
    } else {
      setSecondaryOptions([]);
    }
  }

  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [issueType, setIssueType] = useState("");
  const [secondaryOption, setSecondaryOption] = useState("");
  const [orderInfo, setOrderInfo] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl] = useState("");
  const db = getFirestore();
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const navigate = useNavigate();

  const uploadImage = async () => {
    const storage = getStorage();
    const storageRef = ref(storage, `images/${currentUser.uid}/${Date.now()}`);
    await uploadString(storageRef, image, "data_url");
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!subject || !description) {
      alert("Bitte füllen Sie alle erforderlichen Felder aus.");
      return;
    }

    let uploadedImageUrl = imageUrl;
    if (image) {
      uploadedImageUrl = await uploadImage();
    }

    // Get the current ticket number
    const ticketNumberRef = doc(db, "meta", "ticketNumber");
    const docSnapshot = await getDoc(ticketNumberRef);
    let currentTicketNumber;
    if (docSnapshot.exists()) {
      currentTicketNumber = docSnapshot.data().value;
    } else {
      currentTicketNumber = 1; // If there is no ticket number yet, we start with 1
    }

    const newTicket = {
      subject,
      description,
      issueType,
      imageUrl: uploadedImageUrl,
      userId: currentUser.uid,
      status: "neu",
      timestamp: new Date(),
      ticketNumber: currentTicketNumber,
    };

    try {
      await addDoc(collection(db, "tickets"), newTicket);

      // Update the ticket number in the meta collection
      if (docSnapshot.exists()) {
        await updateDoc(ticketNumberRef, {
          value: currentTicketNumber + 1,
        });
      } else {
        await setDoc(ticketNumberRef, {
          value: 2, // Set the next ticket number to 2 since we used 1 for the current ticket
        });
      }

      alert("Ticket erfolgreich erstellt!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Error creating ticket: ", error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Column>
            <TextField
              fullWidth
              label="Betreff"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </Column>
        </Row>
        <div>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Fehlerart</InputLabel>
            <Select
              label="Fehlerart"
              value={issueType}
              onChange={handleIssueTypeChange}
            >
              <MenuItem value="">Bitte auswählen</MenuItem>
              <MenuItem value="hardware">Hardware</MenuItem>
              <MenuItem value="software">Software</MenuItem>
              <MenuItem value="network">Netzwerk</MenuItem>
              <MenuItem value="orders">Bestellungen</MenuItem>
              <MenuItem value="setupDevice">Gerät einrichten</MenuItem>

              <MenuItem value="andere">Andere</MenuItem>
            </Select>

            {issueType !== "Bestellungen" && (
              <FormControl variant="outlined" style={{ margin: "20px 0" }}>
                <InputLabel>Unter Kategorie</InputLabel>
                <Select
                  value={secondaryOption}
                  onChange={(e) => setSecondaryOption(e.target.value)}
                  label="Unter Kategorie"
                >
                  {secondaryOptions.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {issueType === "Bestellungen" && (
              <TextField
                variant="outlined"
                label="Bestellinformationen"
                fullWidth
                style={{ margin: "20px 0" }}
                value={orderInfo}
                onChange={(e) => setOrderInfo(e.target.value)}
              />
            )}
          </FormControl>
        </div>
        <Column>
          <TextField
            fullWidth
            label="Beschreibung"
            variant="outlined"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Column>
        <div>
          <label>Bild hochladen:</label>
          <Input
            type="file"
            accept="image/*"
            fullWidth
            onChange={handleImageChange}
          />
        </div>
        <Button variant="contained" color="primary" type="submit">
          Ticket erstellen
        </Button>
      </Form>
    </Container>
  );
}

export default CreateTicket;

const Container = styled.div`
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
