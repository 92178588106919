import React from "react";
import Blog from "./Blog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import styled from "styled-components";

function MainPage() {
  return (
    <Container>
      <Box display="flex" justifyContent="center" gap={2} marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/dashboard"
        >
          Meine Tickets
        </Button>
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to="/create-ticket"
        >
          Ticket erstellen
        </Button>
      </Box>
      <Blog />
    </Container>
  );
}

export default MainPage;

const Container = styled.div`
  margin-top: 50px;
`;
