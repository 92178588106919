import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import StatusSymbol from "../helpers/StatusSymbol";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import emailjs from "emailjs-com";

const TicketModal = ({ ticket, onClose }) => {
  const [setIssueType] = useState("");

  function handleIssueTypeChange(event) {
    setIssueType(event.target.value);
    if (secondaryOptionsMap[event.target.value]) {
      setSecondaryOptions(secondaryOptionsMap[event.target.value]);
    } else {
      setSecondaryOptions([]);
    }
  }

  const [setSecondaryOptions] = useState([]);

  const secondaryOptionsMap = {
    hardware: [
      "laptop",
      "handy",
      "Monitor",
      "Tastatur & Maus",
      "drucker",
      "beamer",
      "TV",
      "andere",
    ],
    software: ["OFFICE", "Teams", "outlook", "word", "excel", "andere"],
    Netzwerk: ["Internet", "Verbindungen", "Geschwindigkeit", "andere"],
    "Gerät einrichten": [
      "Laptop",
      "Handy",
      "Netzwerk",
      "Drucker",
      "Monitor",
      "Tastatur & Maus",
      "beamer",
      "TV",
      "andere",
    ],
    Bestellungen: [],
  };

  const [newNote, setNewNote] = useState("");
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [role, setRole] = useState("");
  const db = getFirestore();
  const [editingNote, setEditingNote] = useState(null);
  const [editedNoteText, setEditedNoteText] = useState("");
  const [admins, setAdmins] = useState([]);
  const assignedAdmin = ticket.assignedAdmin || "";

  // Funktion, um die Liste aller Admins zu holen
  useEffect(() => {
    const fetchAdmins = async () => {
      const db = getFirestore();
      const q = query(collection(db, "roles"), where("role", "==", "admin"));
      const querySnapshot = await getDocs(q);
      const adminList = [];
      querySnapshot.forEach((doc) => {
        adminList.push(doc.data().name);
      });
      setAdmins(adminList);
    };

    fetchAdmins();
  }, []);

  // assign admin
  const assignToAdmin = async (adminEmail) => {
    const ticketRef = doc(db, "tickets", ticket.id);
    await updateDoc(ticketRef, {
      assignedAdmin: adminEmail,
    });

    // Update the local ticket state
    ticket.assignedAdmin = adminEmail;
  };

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const userRef = doc(db, "roles", currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setRole(userDoc.data().role);
        }
      }
    };

    fetchUserRole();
  }, [currentUser, db]);

  const addNote = async () => {
    if (newNote.trim()) {
      const ticketRef = doc(db, "tickets", ticket.id);
      const currentTimestamp = new Date();

      try {
        // Get the user's email address after adding a new note
        const userEmail = await getUserEmail(ticket.userId);

        // Send an email notification with the content of the new note.
        sendEmailNotification(userEmail, newNote, ticket.subject);
      } catch (error) {
        console.error("Error sending email notification:", error);
      }

      // Create the note object
      const note = {
        text: newNote,
        user: currentUser.displayName || currentUser.email,
        timestamp: currentTimestamp.toISOString(),
      };

      // Add the note to the ticket in Firestore.
      await updateDoc(ticketRef, {
        notes: arrayUnion(note),
      });

      // Update the local UI
      if (ticket.notes) {
        ticket.notes.push(note);
      } else {
        ticket.notes = [note];
      }
      setNewNote(""); // Empty the text field
    }
  };

  const updateTicketStatus = async (newStatus) => {
    const ticketRef = doc(db, "tickets", ticket.id);
    await updateDoc(ticketRef, {
      status: newStatus,
    });

    // Update the local state to reflect the change.
    ticket.status = newStatus;

    if (newStatus === "erledigt") {
      try {
        const userEmail = await getUserEmail(ticket.userId);
        sendEmailNotification(
          userEmail,
          "Ihr Ticket wurde erledigt. Vielen Dank für Ihre Geduld.",
          ticket.subject,
          "template_k3e4izj"
        );
      } catch (error) {
        console.error("Error sending email notification:", error);
      }
    }
  };

  const canEditNote = (note) => {
    return note.user === (currentUser.displayName || currentUser.email);
  };

  const updateNote = async (index) => {
    const updatedNotes = [...ticket.notes];
    updatedNotes[index].text = editedNoteText;

    const ticketRef = doc(db, "tickets", ticket.id);
    await updateDoc(ticketRef, {
      notes: updatedNotes,
    });

    ticket.notes = updatedNotes;
    setEditingNote(null);
  };

  const deleteNote = async (index) => {
    const updatedNotes = [...ticket.notes];
    updatedNotes.splice(index, 1);

    const ticketRef = doc(db, "tickets", ticket.id);
    await updateDoc(ticketRef, {
      notes: updatedNotes,
    });

    ticket.notes = updatedNotes;
  };

  async function getUserEmail(userId) {
    try {
      const docRef = doc(db, "roles", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data().email;
      } else {
      }
    } catch (e) {
      console.error("Error getting user data:", e);
    }
  }

  const sendEmailNotification = (
    emailTo,
    noteContent,
    ticketSubject,
    templateId = "template_ar8aejv"
  ) => {
    emailjs
      .send(
        "service_0mv49xj",
        templateId,
        {
          to_email: emailTo,
          note_content: noteContent,
          ticket_subject: ticketSubject,
        },
        "UcdGrpwEMry3hvcGc"
      )
      .then(
        (response) => {},
        (error) => {
          console.error("FAILED...", error);
        }
      );
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={style}>
        <CloseButton onClick={onClose}>X</CloseButton>

        {role === "admin" ? (
          <Select
            value={ticket.status || ""}
            onChange={(e) => updateTicketStatus(e.target.value)}
          >
            <MenuItem value="neu">Neu</MenuItem>
            <MenuItem value="inBearbeitung">In Bearbeitung</MenuItem>
            <MenuItem value="erledigt">Erledigt</MenuItem>
          </Select>
        ) : (
          <StatusSymbol status={ticket.status} />
        )}
        <br></br>
        <br></br>
        <Typography variant="h6" component="h2">
          Ticket #{ticket.ticketNumber}
        </Typography>
        <br></br>
        <Typography variant="subtitle" fontWeight="bold" component="h2">
          {ticket.subject}
        </Typography>

        <br></br>
        {role === "admin" ? (
          <Select
            value={assignedAdmin}
            onChange={(e) => assignToAdmin(e.target.value)}
          >
            <MenuItem value="">
              <em>Admin auswählen</em>
            </MenuItem>
            {admins.map((admin, index) => (
              <MenuItem key={index} value={admin}>
                {admin}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography>
            Zugewiesen an: {ticket.assignedAdmin || "Noch nicht zugewiesen"}
          </Typography>
        )}
        <br></br>
        <Typography variant="body1" component="span" fontWeight="bold">
          Fehlerart:
        </Typography>
        <Typography variant="body2" component="span">
          {ticket.issueType}
        </Typography>
        <br></br>

        <Typography variant="body1" component="span" fontWeight="bold">
          Problem:
        </Typography>
        <Typography variant="body2" component="span">
          {ticket.secondaryIssueType}
        </Typography>
        <br></br>

        <Typography variant="body1" component="span" fontWeight="bold">
          Beschreibung:
        </Typography>
        <Typography variant="body2" component="span">
          {ticket.description}
        </Typography>
        {ticket.imageUrl && (
          <img
            src={ticket.imageUrl}
            alt="Ticket-Anhang"
            style={{ maxWidth: "20%", maxHeight: "50%", marginTop: "8px" }}
          />
        )}
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        {/* Note area */}
        <Typography variant="h5" component="h3" marginTop="8px">
          Notizen:
        </Typography>
        {ticket.notes &&
          ticket.notes.map((note, index) => (
            <div key={index}>
              <strong>{note.user}</strong> ({note.timestamp}):
              {editingNote === index ? (
                <div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    rows={4}
                    value={editedNoteText}
                    onChange={(e) => setEditedNoteText(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={() => updateNote(index)}
                  >
                    Aktualisieren
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setEditingNote(null)}
                  >
                    Abbrechen
                  </Button>
                </div>
              ) : (
                <>
                  {note.text}
                  {canEditNote(note) && (
                    <>
                      <EditButton>
                        <EditNoteOutlinedIcon
                          onClick={() => {
                            setEditingNote(index);
                            setEditedNoteText(note.text);
                          }}
                        />
                      </EditButton>
                      <DeleteButton>
                        <DeleteOutlineOutlinedIcon
                          onClick={() => deleteNote(index)}
                        />
                      </DeleteButton>
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        <TextField
          fullWidth
          label="Neue Notiz hinzufügen..."
          variant="outlined"
          multiline
          rows={4}
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />

        <Button variant="contained" color="primary" onClick={addNote}>
          Notiz hinzufügen
        </Button>
      </Box>
    </Modal>
  );
};

export default TicketModal;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 5px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background-color: #023047;
  color: #fff;
  border-style: solid;
  border-radius: 50px;

  &:hover {
    background-color: #fff;
    color: #023047;
  }
`;

const EditButton = styled.button`
  cursor: pointer;
  background: transparent;
  border-width: 0;
`;

const DeleteButton = styled.button`
  cursor: pointer;
  background: transparent;
  border-width: 0;
`;
