import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";

const AddPost = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [timestap, setTimestap] = useState("");

  const navigate = useNavigate();

  const handleAddPost = async (e) => {
    e.preventDefault();

    const db = getFirestore();
    const postsCollection = collection(db, "posts");

    // Get the number of existing posts
    const postsSnapshot = await getDocs(postsCollection);
    const currentIndex = postsSnapshot.size + 1;

    try {
      await addDoc(postsCollection, {
        title,
        content,
        timestap,
        index: currentIndex,
      });
      alert("Post added successfully!");
      setTitle("");
      setContent("");
      setTimestap("");
      navigate("/blog");
    } catch (error) {
      console.error("Error adding post:", error);
    }
  };

  return (
    <AddPostContainer>
      <AddPostHeader>Add New Post</AddPostHeader>
      <StyledForm onSubmit={handleAddPost}>
        <StyledInput
          type="date"
          placeholder="Datum"
          value={timestap}
          onChange={(e) => setTimestap(e.target.value)}
        />
        <StyledInput
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <StyledTextArea
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows="5"
        />
        <StyledButton type="submit">Add Post</StyledButton>
      </StyledForm>
    </AddPostContainer>
  );
};

export default AddPost;

const AddPostContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: auto;
`;

const AddPostHeader = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #3f51b5;
  text-align: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`;

const StyledTextArea = styled.textarea`
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  resize: vertical;

  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #3f51b5;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #303f9f;
  }
`;
