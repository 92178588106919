import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";

import styled from "styled-components";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      const db = getFirestore();
      const postsCollection = collection(db, "posts");
      const postsSnapshot = await getDocs(postsCollection);
      const postsList = postsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      // Sort posts based on the index
      const sortedPosts = postsList.sort((a, b) => b.index - a.index);

      setPosts(sortedPosts);
    };

    const checkUserRole = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const db = getFirestore();
        const userRoleDoc = doc(db, "roles", currentUser.uid);
        const userRoleSnapshot = await getDoc(userRoleDoc);
        if (
          userRoleSnapshot.exists() &&
          userRoleSnapshot.data().role === "admin"
        ) {
          setIsAdmin(true);
        }
      }
    };

    fetchPosts();
    checkUserRole();
  }, []);

  return (
    <Container>
      <Header>News</Header>
      {isAdmin && <AddPostLink to="/add-post">Add New Post</AddPostLink>}
      {posts.map((post) => (
        <PostContainer key={post.id}>
          <PostTitle>{post.title}</PostTitle>
          <PostContent>{post.content}</PostContent>
          <PostDate>{post.timestap}</PostDate>
        </PostContainer>
      ))}
    </Container>
  );
};

export default Blog;

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
`;
const PostDate = styled.span`
  font-size: 0.8em;
  color: #888;
  display: block;
  margin-top: 10px;
`;

const Header = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
  color: #3f51b5;
  text-align: center;
`;

const PostContainer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const PostTitle = styled.h3`
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #3f51b5;
`;

const PostContent = styled.p`
  font-size: 1em;
`;

const AddPostLink = styled(Link)`
  display: block;
  margin: 20px 0;
  color: #3f51b5;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border: 1px solid #3f51b5;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3f51b5;
    color: white;
  }
`;
