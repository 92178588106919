import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link, useNavigate } from "react-router-dom";
import { getDoc, doc, getFirestore } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import LOGO_LOGOTP from "../assets/images/themSorun_logo_tp.png";
import KDLOGO from "../assets/images/kdLOGO.png";

function HeaderNav() {
  const [userImage, setUserImage] = React.useState(null);
  const [setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navigate = useNavigate();
  React.useEffect(() => {
    const fetchUserImage = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;
        const db = getFirestore();
        const userDocRef = doc(db, "roles", uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserImage(userDocSnap.data().image);
        }
      }
    };
    fetchUserImage();
  }, []);

  const handleMenuItemClick = () => {
    setMobileOpen(false);
  };
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error logging out: ", error);
      });
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // side menu
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        {["Main", "Tickets", "Ticket erstellen", "News"].map((text, index) => (
          <ListItem
            button
            key={text}
            component={Link}
            to={
              index === 0
                ? "/mainpage"
                : index === 1
                ? "/dashboard"
                : index === 2
                ? "/create-ticket"
                : "/blog"
            }
            onClick={handleMenuItemClick}
          >
            <Typography>{text}</Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/mainpage"
            sx={{
              mr: 5,
              display: "flex",
              padding: "8px",
            }}
          >
            <img
              src={LOGO_LOGOTP}
              alt="logo"
              width={80}
              style={{ marginRight: "40px" }}
            />

            <img
              src={KDLOGO}
              alt="TopLink"
              width={40}
              height={50}
              style={{ marginTop: "8px" }}
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}>
            <Button
              component={Link}
              to="/mainpage"
              variant="contained"
              color="primary"
              sx={{ marginRight: 1 }}
            >
              Main
            </Button>
            <Button
              component={Link}
              to="/dashboard"
              variant="contained"
              color="primary"
              sx={{ marginRight: 1 }}
            >
              Tickets
            </Button>
            <Button
              component={Link}
              to="/create-ticket"
              variant="contained"
              color="primary"
              sx={{ marginRight: 1 }}
            >
              Ticket erstellen
            </Button>
            <Button
              component={Link}
              to="/blog"
              variant="contained"
              color="primary"
              onClick={handleCloseNavMenu}
            >
              News
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt="Remy Sharp"
                  src={userImage || "/static/images/avatar/2.jpg"}
                  sx={{ width: 48, height: 48 }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                component={Link}
                to="/account"
                onClick={handleCloseUserMenu}
              >
                <Typography textAlign="center">Account</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  handleLogout();
                }}
              >
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>
    </AppBar>
  );
}
export default HeaderNav;
