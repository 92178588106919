import { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import AddUser from "./components/AddUser";
import CreateTicket from "./components/CreateTicket";
import Account from "./components/Account";
import HeaderNav from "./components/HeaderNav";
import Blog from "./components/Blog";
import AddPost from "./components/AddPost";
import MainPage from "./components/MainPage";
import { getAuth } from "firebase/auth";
import app from "./firebase/config";

const AppRoutes = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth(app);
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {user && <HeaderNav />}
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/mainpage" element={<MainPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/adduser" element={<AddUser />} />
        <Route path="/create-ticket" element={<CreateTicket />} />
        <Route path="/account" element={<Account />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/add-post" element={<AddPost />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
