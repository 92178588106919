import React, { useState } from "react";
import styled from "styled-components";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LOGO from "../assets/images/themSorun.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/mainpage");
    } catch (error) {
      console.error("Fehler beim Login:", error);
    }
  };

  return (
    <LoginPage>
      <ImageContainer />

      <LoginFormContainer>
        <Header>themSorun</Header>
        <SubHeader>
          TOP-LINK Packaging <br></br>
          Technology GmbH
        </SubHeader>
        <LoginForm onSubmit={handleLogin}>
          <LoginInput
            type="email"
            placeholder="E-Mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <LoginInput
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <LoginButton type="submit">Anmelden</LoginButton>
          <HelpButton
            onClick={() =>
              (window.location.href = "mailto:hello@themstudio.de")
            }
          >
            Probleme beim Anmelden?
          </HelpButton>
        </LoginForm>
      </LoginFormContainer>
    </LoginPage>
  );
};

export default Login;

const LoginPage = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.h2``;

const SubHeader = styled.h3`
  text-align: center;
`;

const ImageContainer = styled.div`
  flex: 1;
  background-image: url(${LOGO});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: 30vh;
    flex: none;
    background-size: 50%;
  }
`;

const LoginFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const LoginInput = styled.input`
  margin-bottom: 10px;
  padding: 10px;
`;

const LoginButton = styled.button`
  background-color: #30d5c8;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;
